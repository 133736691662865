import React, { useState } from "react";
import { Link } from "gatsby";
import classnames from "classnames";
import { useKonami } from "react-konami-code";

import SideBar from "../components/sidebar";

export default function Layout({ children, location, pageContext }) {
  const currentPage = location.pathname.split("/")[1] || "blog";
  const [disco, setDisco] = useState(false);

  // Toggle disco mode
  useKonami(() => setDisco(!disco));

  if (pageContext.layout === "redirect" && pageContext.redirectTo) {
    typeof window !== "undefined" &&
      (window.location.href = pageContext.redirectTo);
    return null;
  }

  return (
    <>
      <div
        className={classnames(
          "wrap",
          { [currentPage]: !!currentPage },
          { konami: disco }
        )}
      >
        <aside className="nav">
          <header>
            <Link to="/">
              <img src="/images/face.gif" alt="" />
            </Link>
            <h1>
              <Link to="/">
                Rémy Bach<small>Front End Developer</small>
              </Link>
            </h1>
          </header>
          <SideBar currentPage={currentPage} />
        </aside>
        <div className="content">{children}</div>
        <script
          async
          src="//cdn.embedly.com/widgets/platform.js"
          charSet="UTF-8"
        ></script>
      </div>
    </>
  );
}
