import * as React from "react";
import { Link } from "gatsby";
import classnames from "classnames";

export default function SideBar({ currentPage }) {
  return (
    <>
      <nav className="main">
        <ul>
          <li>
            <Link
              to="/"
              className={classnames("blog", {
                active: currentPage === "blog",
              })}
            >
              Blog
            </Link>
          </li>
          <li>
            <Link
              to="/get-in-touch"
              className={classnames("get-in-touch", {
                active: currentPage === "get-in-touch",
              })}
            >
              Get in touch
            </Link>
          </li>
          <li>
            <Link
              to="/work"
              className={classnames("work", {
                active: currentPage === "work",
              })}
            >
              Work
            </Link>
          </li>
          <li>
            <Link
              to="/about"
              className={classnames("about", {
                active: currentPage === "about",
              })}
            >
              About me
            </Link>
          </li>
        </ul>
      </nav>
      <nav className="social">
        <ul>
          <li>
            <a href="http://lnkd.in/XxFHCQ" className="social-linkedin">
              LinkedIn
            </a>
          </li>
          <li>
            <a href="https://github.com/remybach" className="social-github">
              remybach on github
            </a>
          </li>
          <li>
            <a href="http://codepen.io/remybach" className="social-codepen">
              remybach on codepen
            </a>
          </li>
        </ul>
      </nav>
    </>
  );
}
